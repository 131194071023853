import React from "react"
import { graphql } from "gatsby"

import Form from "components/form"
import Heading from "components/heading"
import Layout from "components/layout/layout"
import Row from "components/row"
import SEO from "components/seo"
import Image from "safe-gatsby-image-plugin"

import l from "components/layout/layout.module.scss"
import d from "./downloads.module.scss"
import { seoDataFromPage } from "components/utils/data-formatting"

const Sample = ({ pageContext, data: queryData }) => {
  const { title } = pageContext
  const { acf } = queryData.wordpressPage
  const { wordpress_fields: formFields, submit_bt: btnLabel } = acf.sample.form
  const meta = seoDataFromPage(queryData.wordpressPage)
  return (
    <Layout>
      <SEO title={title} meta={meta} />
      <Row customClass={l.archive_title_section}>
        <div className={d.grid}>
          <div>
            <Heading type="h1" color="white">
              {title}
            </Heading>
            <div dangerouslySetInnerHTML={{ __html: acf.sample.text }} />
          </div>
          <Image data={acf.sample.image} />
          <div className={[d.form, d.rem_2].join(" ")}>
            <Form fields={formFields} btnLabel={btnLabel} accent="light" />
          </div>
        </div>
      </Row>
    </Layout>
  )
}

export default Sample

// add page query
export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      acf {
        sample {
          text
          image {
            ...ImageFullWidth
          }
          form {
            submit_bt
            wordpress_fields {
              label
              required
              type
              width
            }
          }
        }
      }
      ...SEOData
    }
  }
`
